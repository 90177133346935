<template>
  <div class="wrapper">
    <el-drawer size="45%" :visible.sync="show_detail" direction="rtl">
      <!-- 用户信息 -->
      <div class="user-info">
        <el-image
          style="width: 60px; height: 60px; border-radius: 5px; display: block"
          :src="detail.face"
          :preview-src-list="[detail.face]"
        >
          <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
        </el-image>
        <div class="info">
          <div class="nickname">
            {{ detail.nickname }}
            <el-tag v-if="userHistory.status == 20" type="success" size="mini">通过</el-tag>
            <el-tag v-if="userHistory.status == 10" type="danger" size="mini">不通过</el-tag>
          </div>
          <div class="sex">
            <el-tag v-if="detail.sex == 1" type="primary" size="mini">男</el-tag>
            <el-tag v-if="detail.sex == 2" type="success" size="mini">女</el-tag>
            <el-tag v-if="detail.sex == 3 || !detail.sex" type="warning" size="mini">未知</el-tag>
          </div>
        </div>
        <!--            <div class="info" style="text-align: right">-->
        <!--                <el-link type="primary" :underline="false" @click="handleReview(userHistory)">审核</el-link>-->
        <!--            </div>-->
      </div>
      <!-- tabs -->
      <div class="tabs">
        <el-tabs v-model="activeName">
          <el-tab-pane label="真人视频" name="first">
            <div class="video-box" v-if="detail.liveVideo">
              <video class="video" :src="detail.liveVideo" controls></video>
            </div>
          </el-tab-pane>
          <el-tab-pane label="照片墙" name="five">
            <div class="photos" v-if="detail.userPhotos">
              <div class="item" v-for="(item, i) in detail.userPhotos" :key="i">
                <el-image
                  style="width: 150px; height: 150px; border-radius: 5px; display: block"
                  :src="item"
                  fit="cover"
                  :preview-src-list="[...detail.userPhotos]"
                >
                  <div style="line-height: 150px; text-align: center; background: #f5f7fa" slot="error">无</div>
                </el-image>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="标签" name="second">
            <el-tag style="margin-right: 10px" type="primary" v-for="(item, i) in detail.userTags" :key="i">
              {{ item }}
            </el-tag>
          </el-tab-pane>
          <el-tab-pane label="财名认证" name="fourth">
            <admin-title title="身份证照片" bottom="10px"></admin-title>
            <div class="list" v-if="detail.caiMingAuth">
              <div class="item">
                <el-image
                  style="width: 200px; height: 100px; border-radius: 5px; display: block"
                  :src="detail.caiMingAuth?.idCardFront"
                  fit="cover"
                  :preview-src-list="[detail.caiMingAuth?.idCardFront]"
                >
                  <div style="line-height: 100px; text-align: center; background: #f5f7fa" slot="error">无</div>
                </el-image>
                <span>人像面</span>
              </div>
              <div class="item">
                <el-image
                  style="width: 200px; height: 100px; border-radius: 5px; display: block"
                  :src="detail.caiMingAuth?.idCardBack"
                  fit="cover"
                  :preview-src-list="[detail.caiMingAuth?.idCardBack]"
                >
                  <div style="line-height: 100px; text-align: center; background: #f5f7fa" slot="error">无</div>
                </el-image>
                <span>国徽面</span>
              </div>
            </div>
            <div class="title-box">
              <admin-title title="财富证明" bottom="0px"></admin-title>
              <div class="btn">
                <el-button type="primary" size="mini" @click="handleReview(1)">审核</el-button>
              </div>
            </div>
            <div class="pic-box" v-if="detail.caiMingAuth">
              <div class="content">
                <el-image
                  style="width: 200px; height: 100px; border-radius: 5px; display: block"
                  :src="detail.caiMingAuth?.caifuAuth"
                  fit="cover"
                  :preview-src-list="[detail.caiMingAuth?.caifuAuth]"
                >
                  <div style="line-height: 100px; text-align: center; background: #f5f7fa" slot="error">无</div>
                </el-image>
              </div>
            </div>

            <div class="title-box">
              <admin-title title="其他平台认证" bottom="0px"></admin-title>
              <div class="btn">
                <el-button type="primary" size="mini" @click="handleReview(2)">审核</el-button>
              </div>
            </div>
            <div class="video-box" v-if="detail.caiMingAuth">
              <video class="video" :src="detail.caiMingAuth?.otherPlatformAuth" controls></video>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 审核弹窗 -->
      <el-dialog title="审核" :visible.sync="show_review" width="width" :append-to-body="true">
        <el-form ref="form" :model="reviewData" label-width="80px">
          <el-form-item label="状态">
            <el-radio-group v-model="reviewData.auth">
              <el-radio :label="0">不通过</el-radio>
              <el-radio :label="1">通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="备注">
            <el-input style="width: 300px" type="textarea" v-model="reviewData.auditResult"></el-input>
          </el-form-item> -->
        </el-form>
        <div slot="footer">
          <el-button @click="show_review = false" size="small">取 消</el-button>
          <el-button type="primary" @click="submitReview" size="small">确 定</el-button>
        </div>
      </el-dialog>
    </el-drawer>
  </div>
</template>

<script>
import { reviewCaifuAPI, reviewOtherAPI } from '@/views/customer/review-list/api'

export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    userHistory: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeName: 'first',
      show_review: false,
      show_detail: false,
      reviewData: {
        id: '',
        auth: 1
      },
      type: '' //1财富证明   2其他平台
    }
  },

  mounted() {},

  methods: {
    // 审核
    handleReview(i) {
      this.reviewData = {
        id: this.userHistory.id,
        auth: 1
      }
      this.type = i
      this.show_review = true
    },

    submitReview() {
      if (this.type == 1) {
        // 财富证明
        reviewCaifuAPI(this.reviewData).then(() => {
          this.$message.success('审核成功')
          this.show_review = false
        })
      }
      if (this.type == 2) {
        // 其他平台名人认证
        reviewOtherAPI(this.reviewData).then(() => {
          this.$message.success('审核成功')
          this.show_review = false
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  .user-info {
    display: flex;
    padding: 0 20px;

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 20px;
    }
  }

  .tabs {
    padding: 20px;

    .video-box {
      text-align: left;
      padding: 0 20px;

      .video {
        height: 400px;
        object-fit: contain;
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(2, 200px);
      gap: 20px;
      // justify-content: space-around;
      .item {
        text-align: center;

        span {
          margin-top: 10px;
        }
      }
    }

    .pic-box {
      display: flex;

      margin-bottom: 20px;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }

  .photos {
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    gap: 20px;
    justify-content: left;
  }
  .title-box {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    .btn {
      margin-left: 20px;
    }
  }
}
</style>
