<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- 搜索 -->
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="昵称">
        <el-input style="width: 200px" clearable v-model="searchData.nickname" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge :value="stat.total">
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge :value="stat.waitCount">
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge :value="stat.passCount">
          <span class="tab-name">已通过</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge :value="stat.rejectCount">
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
    </div>

    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="title" label="昵称" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.userInfo?.nickname }}
        </template>
      </el-table-column>
      <el-table-column prop="face" label="头像" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-image
            style="width: 60px; height: 60px; border-radius: 5px; display: block"
            :src="row.userInfo?.face"
            fit="cover"
            :preview-src-list="[row.userInfo?.face]"
          >
            <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="年龄" min-width="90" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.userInfo?.age }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="性别" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.userInfo.sex == 1" type="primary" size="mini">男</el-tag>
          <el-tag v-if="row.userInfo.sex == 2" type="success" size="mini">女</el-tag>
          <el-tag v-if="row.userInfo.sex == 3 || !row.userInfo.sex" type="warning" size="mini">未知</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="身高" min-width="90" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.userInfo?.height }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="星座" min-width="90" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.userInfo?.constellation }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="喜欢类型" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.userInfo?.likeType }}
        </template>
      </el-table-column>

      <el-table-column prop="title" label="状态" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="mini">待审核</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="mini">已拒绝</el-tag>
          <el-tag v-if="row.status == 20" type="primary" size="mini">已通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="审核结果" min-width="120">
        <template slot-scope="{ row }">
          {{ row.auditResult }}
        </template>
      </el-table-column>

      <el-table-column prop="updateTime" label="更新时间" min-width="160"></el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)"> 详情 </el-link>
          <el-link type="primary" :underline="false" @click="handleReview(row)">审核</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 详情弹窗 -->

    <Detail ref="detail" :detail="detail" :userHistory="userHistory"></Detail>
    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="show_review" width="700px">
      <el-form ref="form" :model="reviewData" label-width="80px">
        <el-form-item label="状态">
          <el-radio-group v-model="reviewData.status">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="10">不通过</el-radio>
            <el-radio :label="20">通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input style="width: 300px" type="textarea" v-model="reviewData.auditResult"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="show_review = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitReview">确 定</el-button>
      </div>
    </el-dialog>
    <!-- jubao  -->
    <JubaoList ref="jubao" :uid="uid"></JubaoList>
  </div>
</template>

<script>
import { getListAPI, reviewAPI, getStatAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import Detail from './detail.vue'
import JubaoList from './jubao-list.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { Detail, JubaoList },
  data() {
    return {
      show_review: false,
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        nickname: '',
        status: -1
      },
      total: 0,
      detail: {},
      userHistory: {},
      reviewData: {
        id: '',
        status: 20,
        auditResult: ''
      },
      uid: '',
      stat: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    // 列表
    async getList() {
      const { page, pagesize, status, nickname } = this.searchData
      let params = { page, pagesize, nickname }
      if (status != -1) {
        params.status = status
      }
      const res = await getListAPI(params)
      this.total = res.totalRow
      this.list = res.records
      this.getStat()
    },
    async getStat() {
      this.stat = await getStatAPI()
    },
    // 点击tab
    handleClickTab(i) {
      if (this.searchData.status == i) return
      this.searchData.status = i
      this.getList()
    },
    // 详情
    handleDetail(row) {
      // this.show_detail = true
      this.$refs.detail.show_detail = true
      this.detail = row.userInfo
      this.userHistory = row
    },
    // 审核
    handleReview({ id }) {
      this.reviewData = {
        id: '',
        status: 20,
        auditResult: ''
      }
      this.show_review = true
      this.reviewData.id = id
    },
    submitReview() {
      reviewAPI(this.reviewData).then(() => {
        this.show_review = false
        this.getList()
        this.$bus.$emit('updateTip')
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleJubao({ uid }) {
      this.uid = uid
      this.$refs.jubao.show_jubao = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
