import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/manage/user/profileHistory/queryPage`,
    method: 'get',
    params
  })
}
// 审核用户资料
export function reviewAPI(data) {
  return http({
    url: `/manage/user/profileHistory/audit`,
    method: 'post',
    data
  })
}
// 审核资产-财富
export function reviewCaifuAPI(data) {
  return http({
    url: `/manage/user/profileHistory/authCaili`,
    method: 'post',
    data
  })
}
// 审核资产-其他平台名人
export function reviewOtherAPI(data) {
  return http({
    url: `/manage/user/profileHistory/authMingren`,
    method: 'post',
    data
  })
}
//举报列表
export function getJubaoListAPI(params) {
  return http({
    url: `/manage/user/pageReport`,
    method: 'get',
    params
  })
}
// 统计
export function getStatAPI(params) {
  return http({
    url: `/manage/user/profileHistory/stat`,
    method: 'get',
    params
  })
}